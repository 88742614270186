import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ForgotPassword from "../views/auth/ForgotPassword";
import LogInForm from "../views/auth/LogInForm";
import SignUpForm from "../views/auth/SignUpForm";
import AdminDashboard from "../views/dashboard/admin/AdminDashboard";
import PrivateComponent from "../views/auth/PrivateComponent";
import VerifyAccount from "../views/auth/VerifyAccount";
import ResetPassword from "../views/auth/ResetPassword";
import UserDashboard from "../views/dashboard/user/UserDashboard";
import Profile from "../views/profile/Profile";
import AdminSolPurchaseHistory from "../views/dashboard/admin/AdminSolPurchaseHistory";
import LFCPurchaseHistory from "../views/dashboard/user/LFCPurchaseHistory";
import AdminLFCPurchaseHistory from "../views/dashboard/admin/AdminLFCPurchaseHistory";
import SolPurchaseHistory from "../views/dashboard/user/SolPurchaseHistory";
import AdminLFCDeposit from "../views/dashboard/admin/AdminLFCDeposit";
import RoadMap from "../views/dashboard/user/RoadMap";
import Community from "../views/dashboard/user/Community";
import Main from "../views/profile/Main";
import Purchase from "../views/dashboard/user/Purchase";

const RouterPage = () => {
  return (
    <Router>
      <div>
        <Routes>
          {/* <Route path="/login" element={<LogInForm />} />
          <Route path="/sign-up" element={<SignUpForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-account/:token" element={<VerifyAccount />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} /> */}
          <Route path="/" element={<Main />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route element={<PrivateComponent />}>
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/admin-solana-purchase-history"
              element={<AdminSolPurchaseHistory />}
            />
            <Route
              path="/solana-purchase-history"
              element={<SolPurchaseHistory />}
            />
            <Route
              path="/lfc-purchase-history"
              element={<LFCPurchaseHistory />}
            />
            <Route
              path="/admin-lfc-purchase-history"
              element={<AdminLFCPurchaseHistory />}
            />
            <Route path="/admin-lfc-deposit" element={<AdminLFCDeposit />} />
            <Route path="/roadmap" element={<RoadMap />} />
            <Route path="/community" element={<Community />} />
            <Route path="/purchase" element={<Purchase />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default RouterPage;
