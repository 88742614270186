import { Connection, PublicKey } from "@solana/web3.js";

export const swap_authority = "7ZCupJ39bSLz6nvzCmjpbZ4ZZjEHqk4Sszc2r9hagnx2";

export const LFC_PAIR = new PublicKey(
  "2rKK8zHYXDuKcfRNLVLWfu21ZX75Wy3i5AF4gQkhdEAN"
);
export const LP_TOKEN = new PublicKey(
  "LbZqahz7ig48hzTEsBarNB2Fyx3vxKTG4v68PPesGrv"
);

export const TOKEN_SWAP_PROGRAM_ID = new PublicKey(
  "627ve5c4kiqBpVTHRTdyVQG3keyESZPLmgJQ3MDJrzdT"
);
export const fee_owner = new PublicKey(
  "HfoTxFR1Tm6kGmWgYWD6J7YHVy1UwqSULUGVLXkJqaKN"
);

export const tokenSwap = new PublicKey(
  "TbueXf7QwvFNGUGFAaAkuhFpXBsQZQYbz3qZ6EoHV9y"
);

export const rpc =
  "https://devnet.helius-rpc.com/?api-key=976801c8-1237-4e44-94e3-3f03f9a1212d";

export const connection = new Connection(rpc);

export const lfcAccountAddress = new PublicKey("CUcNUReFVAN6sD59bSgdzypLgJ4HVJ96UdimcPgfucZ6")

export const wSolAccountAddress = new PublicKey("Hqgn3TvfLNnXBKuW5Q2rtmD1oZBpjzMNc5s5EaGXmx4o")

export const TOKEN_A_DECIMALS = 1_000_000;

export const TOKEN_B_DECIMALS = 1_000_000_000;


