import React, { useState } from "react";
import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { TokenSwap } from "@solana/spl-token-swap";
import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  NATIVE_MINT,
  createSyncNativeInstruction,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  LFC_PAIR,
  LP_TOKEN,
  TOKEN_SWAP_PROGRAM_ID,
  fee_owner,
  tokenSwap,
} from "../../constants/constants";
import axios from "axios";
import Swal from "sweetalert2";
import SwapInfo from "./getPrice";

const rpc = process.env.REACT_APP_RPC_URL;

const getTokenAddressAndInstruction = async (
  mint,
  owner,
  payer,
  connection
) => {
  let tokenAccountAddress = await getAssociatedTokenAddress(mint, owner, true);
  const accountInfo = await connection.getAccountInfo(tokenAccountAddress);
  if (accountInfo !== null) {
    return [tokenAccountAddress, null];
  }
  let tokenAccountCreationInstruction = createAssociatedTokenAccountInstruction(
    payer,
    tokenAccountAddress,
    owner,
    mint
  );
  return [tokenAccountAddress, tokenAccountCreationInstruction];
};

const LiquidityPool = ({ _wallet, solAmount, isOpen, setIsOpen, setInput }) => {
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);

  const connection = new Connection(rpc);

  const User = localStorage.getItem("user");
  const parsedUser = JSON.parse(User);
  const token = parsedUser.auth;

  const handleConnectWallet = () => {
    setIsOpen(!isOpen);
  };

  const sendTransactionDetails = async (details) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/token/storeTokenSwaping`,
        details,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Transaction details sent to backend successfully", details);
    } catch (error) {
      console.error("Error sending transaction details:", error);
    }
  };

  const handleClick = () => {
    setClicked(true);
  };

  const handleLiquidityPool = async () => {
    const secondUser = _wallet;

    try {
      setLoading(true);
      const transaction = new Transaction();

      const [swapAuthority] = PublicKey.findProgramAddressSync(
        [tokenSwap.toBuffer()],
        TOKEN_SWAP_PROGRAM_ID
      );

      const [userLfcTokenAccount, userLfcTokenAccountCreationInstruction] =
        await getTokenAddressAndInstruction(
          LFC_PAIR,
          secondUser,
          secondUser,
          connection
        );

      if (userLfcTokenAccountCreationInstruction) {
        transaction.add(userLfcTokenAccountCreationInstruction);
      }

      const [
        wSOltokenAccountOfUser,
        wSOltokenAccountOfUserCreationInstruction,
      ] = await getTokenAddressAndInstruction(
        NATIVE_MINT,
        secondUser,
        secondUser,
        connection
      );

      if (wSOltokenAccountOfUserCreationInstruction) {
        transaction.add(wSOltokenAccountOfUserCreationInstruction);
      }

      const transferSolInstruction = SystemProgram.transfer({
        fromPubkey: secondUser,
        toPubkey: wSOltokenAccountOfUser,
        lamports: solAmount * LAMPORTS_PER_SOL,
      });
      transaction.add(transferSolInstruction);

      const syncNativeInstruction = createSyncNativeInstruction(
        wSOltokenAccountOfUser
      );
      transaction.add(syncNativeInstruction);

      const tokenFeeAccountAddress = await getAssociatedTokenAddress(
        LP_TOKEN,
        fee_owner,
        true
      );

      const lfcAta = await getAssociatedTokenAddress(
        LFC_PAIR,
        swapAuthority,
        true
      );
      const wSOLAta = await getAssociatedTokenAddress(
        NATIVE_MINT,
        swapAuthority,
        true
      );

      const tokenSwapInitSwapInstruction = TokenSwap.swapInstruction(
        tokenSwap,
        swapAuthority,
        secondUser,
        wSOltokenAccountOfUser,
        wSOLAta,
        lfcAta,
        userLfcTokenAccount,
        LP_TOKEN,
        tokenFeeAccountAddress,
        null,
        NATIVE_MINT,
        LFC_PAIR,
        TOKEN_SWAP_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        solAmount * LAMPORTS_PER_SOL,
        0n
      );

      transaction.add(tokenSwapInitSwapInstruction);

      let recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
      transaction.recentBlockhash = recentBlockhash;
      transaction.feePayer = secondUser;

      const signature = await window.solana.signAndSendTransaction(transaction);

      await connection.confirmTransaction(signature.signature, "confirmed");

      console.log("The transactoin wsa successful: ", signature);

      const _sign = signature.signature;

      const details = await getTransactionDetails(_sign);

      console.log("The details are: ", details);

      await sendTransactionDetails(details);
      Swal.fire({
        title: "Success!",
        text: "Transaction completed successfully!",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: "Close",
        confirmButtonText: "Check on Explorer",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!_sign) {
            console.error("Signature not available");
            return;
          }
          const url = `https://explorer.solana.com/tx/${_sign}?cluster=devnet`;

          window.open(url, "_blank", "noopener,noreferrer");
        }
      });
      setLoading(false);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Transaction failed. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      console.error("Error processing transaction:", error);
      setLoading(false);
    }
  };

  const getTransactionDetails = async (signature) => {
    try {
      const transaction = await connection.getTransaction(signature, {
        commitment: "confirmed",
      });

      console.log("The signature: ", transaction);

      if (transaction) {
        const buyAtDate = new Date(transaction.blockTime * 1000);
        const walletAddress =
          transaction.transaction.message.accountKeys[0].toString();
        const fee = transaction.meta.fee / LAMPORTS_PER_SOL;
        const updatedBalance =
          transaction.meta.postTokenBalances[1].uiTokenAmount.uiAmount;
        const previousTokenBalance =
          transaction.meta.preTokenBalances[1].uiTokenAmount.uiAmount;
        const buyTokenQuantity = updatedBalance - previousTokenBalance;

        const presSolBalance = transaction.meta.preBalances[0];
        const postSolBalances = transaction.meta.postBalances[0];

        const payedSolanaQuantity =
          (presSolBalance - postSolBalances - fee) / LAMPORTS_PER_SOL;

        console.log("Transaction Fetched and Verified");

        return {
          signature,
          buyAtDate,
          walletAddress,
          fee,
          updatedBalance,
          payedSolanaQuantity,
          buyTokenQuantity,
        };
      } else {
        throw new Error("Transaction not Found");
      }
    } catch (error) {
      console.error("Error fetching transactions: ", error);
      throw error;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          {_wallet ? (
            <>
              <div className="wallet-input">
                <div className="connect-wallet">
                  <div className="connect-wallet-one">
                    {
                      !clicked && (
                        <button
                          className="btn"
                          onClick={handleClick}
                          disabled={loading}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Buy LFC Tokens"
                          )}
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
              {clicked && (
                <SwapInfo
                  inputAmount={solAmount}
                  setClicked={setClicked}
                  handleLiquidityPool={handleLiquidityPool}
                  setInput={setInput}
                />
              )}
            </>
          ) : (
            <div className="connect-wallet-one">
              <button onClick={handleConnectWallet}>Connect Wallet</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LiquidityPool;
