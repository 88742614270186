import { Keypair } from "@solana/web3.js";
import { raw } from "../constants/StateRawKey";
import { Connection } from "@solana/web3.js";
import { AccountLayout } from "@solana/spl-token";
import BigNumber from "bignumber.js";
import {
  lfcAccountAddress,
  wSolAccountAddress,
  TOKEN_A_DECIMALS,
  TOKEN_B_DECIMALS,
} from "../constants/constants";
const rpc = process.env.REACT_APP_RPC_URL;

const connection = new Connection(rpc);

function getKeypairFromU8Array(secretKeyArray) {
  try {
    // Ensure the array is a Uint8Array
    const secretKeyUint8Array = new Uint8Array(secretKeyArray);

    // Create and return the Keypair from the secret key
    const keyPair = Keypair.fromSecretKey(secretKeyUint8Array);
    return keyPair;
  } catch (error) {
    console.error("Error loading keypair:", error.message);
    return 500;
  }
}

const value = getKeypairFromU8Array(raw);

// Create the Keypair from the secret key
export const tokenSwapStateAccount = value;

export function loadKeypairfromRAW(raw) {
  const keypair = Keypair.fromSecretKey(new Uint8Array(raw));
  return keypair;
}

const lfcAccount = lfcAccountAddress;
const wSolAccount = wSolAccountAddress;

const fetchTokenBalances = async () => {
  try {
    const lfcAccountInfo = await connection.getAccountInfo(lfcAccount);
    const wSolAccountInfo = await connection.getAccountInfo(wSolAccount);

    if (lfcAccountInfo && wSolAccountInfo) {
      const lfcData = AccountLayout.decode(lfcAccountInfo.data);
      const wSolData = AccountLayout.decode(wSolAccountInfo.data);
      return [BigNumber(lfcData.amount), BigNumber(wSolData.amount)];
    }
  } catch (error) {
    console.error("Error fetching token balances:", error);
  }
};

export const calculateExchangeRate = async (inputAmount) => {
  const [lfcBalance, wSolBalance] = await fetchTokenBalances();

  if (lfcBalance > 0n && wSolBalance > 0n) {
    const inputAmountScaled = BigNumber(inputAmount).times(
      BigNumber(TOKEN_B_DECIMALS)
    ); // Scale up for precision
    const constant = lfcBalance.times(wSolBalance);
    const newYBalance = constant.div(wSolBalance.plus(inputAmountScaled));
    const outputAmount = lfcBalance.minus(newYBalance);
    // Calculate fees (example: 0.3% fee)
    const fee = outputAmount.times(2).div(1000);
    const burn_fee = outputAmount.times(5).div(10000);
    const outputAmountAfterFee = outputAmount.minus(fee).minus(burn_fee);
    const rate = outputAmountAfterFee.div(BigNumber(TOKEN_A_DECIMALS)); // Scale back down
    return [rate, fee, burn_fee, outputAmount];
  }
};
