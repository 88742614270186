import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";
import { TOKEN_A_DECIMALS } from "../../constants/constants";
import { calculateExchangeRate } from "../../utils/helper";

const SwapInfo = ({
  inputAmount,
  setClicked,
  handleLiquidityPool,
  setInput,
  getExchangeRate
}) => {
  console.log("Clicked------------");
  const [exchangeRate, setExchangeRate] = useState(new BigNumber(0));
  const [swapFee, setSwapFee] = useState(new BigNumber(0));
  const [burnFee, setBurnfee] = useState(new BigNumber(0));
  const [preFee, setPreFee] = useState(new BigNumber(0));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [rate, fee, burn_fee, outputAmount] = await calculateExchangeRate(
      inputAmount
    );
    setExchangeRate(rate);
    setInput({sol: inputAmount, lfc: rate})
    setSwapFee(fee / BigNumber(TOKEN_A_DECIMALS));
    setBurnfee(burn_fee / BigNumber(TOKEN_A_DECIMALS));
    setPreFee(outputAmount / BigNumber(TOKEN_A_DECIMALS));
  };

  const handleCLick = async () => {
    await handleLiquidityPool();
    setClicked(false);
    const flushInput = {
      sol: "",
      lfc: "",
    };
    setInput(flushInput);
  };

  return (
    <>
      {setClicked && (
        <div>
          <div>
            {/* <label>Input Amount SOL: </label> */}
            <label>Details: </label>
          </div>
          <p>Pre Fee Amount: {preFee.toFixed(6)} LFC</p>
          <p>
            Exchange Rate: {inputAmount} SOL ≈ {exchangeRate.toFixed(6)} LFC
          </p>
          <p>Swap Fee: {swapFee.toFixed(6)} LFC</p>
          <p>Burn Fee: {burnFee.toFixed(6)} LFC</p>
          <div className="connect-wallet-one">
            <button onClick={handleCLick}> Proceed</button>
          </div>
        </div>
      )}
    </>
  );
};

export default SwapInfo;
